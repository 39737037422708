import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import PolicyContetns from "../components/Tests/PolicyContetns";

const View = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;
const Text = styled.span``;

function Policy() {
  return (
    <View>
      <Header title="RiskZero_HG 개인정보 처리방침" />
      <PolicyContetns />
    </View>
  );
}

export default React.memo(Policy);
