import { createGlobalStyle } from "styled-components";
import reset from "styled-reset";
import { colors } from "./colors";

export default createGlobalStyle`
    ${reset};
    * {
        box-sizing:border-box;
    }
    body {
        font-size: 16px;
        font-family: 'SF Pro Display', sans-serif;
        height: 100vh;
        box-sizing: border-box;
        scrollbar-width: none;
        -ms-overflow-style: none;
        body::-webkit-scrollbar {display: none;}
        //min-width: 440px;
        max-width: 1920px;
      
        
    }
    a {
      text-decoration: none;
      color: ${colors.black}; 
    }
    input:focus{
      outline: none;
    }
    button,
    select {
      cursor: pointer;
      -webkit-appearance: none; /* 화살표 없애기 for chrome*/
      -moz-appearance: none; /* 화살표 없애기 for firefox*/
      appearance: none; /* 화살표 없애기 공통*/
    }
`;
