import React from "react";
import styled from "styled-components";

const View = styled.div`
  flex: 1;
`;
const Head = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.span`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <View>
    <Body>
      ㈜리스크제로는(이하 “회사”라 합니다) 어플리케이션을 비롯한 제공
      서비스(이하 “서비스”라 합니다)를 이용하는 “회원” 의 개인정보를 매우
      중요하게 생각하며 다음과 같은 개인정보취급 방침을 가지고 있습니다. 이
      개인정보취급방침은 개인정보보호와 관련한 법령 또는 지침의 변경이 있는 경우
      갱신되고, “회사” 정책의 변화에 따라 달라질 수 있으며 중요 사항의 경우 변경
      전 “회원”에게 그 사실을 알려드립니다.
    </Body>
    <Head>• 개인정보의 수집목적 및 이용목적</Head>
    <Body>
      “회사”가 “회원”의 개인정보를 수집하는 목적은 “회원”의 신분을 확인하고
      “회사”의 서비스를 이용하고자 하는 의사를 확인하며, “회원”에게 최적의
      서비스를 제공하고자 하는 것입니다. “회사”는 전자상거래 및 이와 관련한
      다양한 서비스를 “회원”들의 특성, 기호에 맞추어 제공하기 위하여 아래와 같이
      수집 정보별 목적에 따라 개인정보를 이용하고 있습니다. 또한 수집항목은
      수집목적 및 이용목적을 벗어나지 않는 범위에서 추가 서비스제공을 위해
      확장될 수 있으며, 이 경우에는 미리 “회원”에게 그 사실을 알려드립니다.
      <br />
      <br />
      (1) 필수 수집항목 “회원”가입 및 “서비스” 이용 시 필수적으로 입력 및
      수집하는 항목입니다.
      <br />
      가. 아이디(휴대폰번호), 성명 : 서비스이용에 따른 민원사항의 처리,
      본인식별, “회원”의 인구통계학적 통계
      <br />
      나. 주소, 기업명, 이메일주소, 휴대폰번호 및 정보수신동의 : 공지사항 전달,
      문자 및 알림톡, 1:1 문의 등의 소통경로 확보 및 안내/이벤트 정보제공을
      비롯한 마케팅 및 광고에 활용 등<br />
      <br />
      (2) 자동수집항목 IP Address, 방문 일시, 서비스 이용 기록, 불량 이용 기록 :
      불인가”회원”의 접근방지, 비정상 이용 금지 및 원활한 서비스 제공
      <br />
    </Body>
    <Head>• 개인정보의 제3자의 제공</Head>
    <Body>
      (1) “회사”는 원칙적으로 개인을 식별할 수 있는 정보를 제3자에게 제공하지
      않습니다. 정보를 제 3자에게 제공할 경우 “회원”에게 사전에 안내 및
      동의절차를 거치며, “회원”은 개인정보의 제3자의 제공에 대해 동의하거나
      거부할 수 있습니다.
      <br />
      (2) 법령에 의하여 정부 및 관련 기관으로부터 적법한 절차에 따라 정보 제공을
      요청 받는 경우, “회사”는 “회원”에게 사전 안내 및 동의절차를 거치지 않고
      정부 및 관련기관에 제공될 수 있습니다.
    </Body>
    <Head>• 개인정보의 보유 및 이용기간, 파기절차 및 파기방법</Head>
    <Body>
      (1) “회원”이 “서비스”를 이용하는 동안 “회원”님의 개인정보는 “회사”가 계속
      보유하며 “서비스”의 원활한 제공을 위해 이용하게 됩니다.
      <br />
      다만 “회원”이 “회원”을 탈퇴하거나 가입 해지를 요청한 경우, 또는 개인정보를
      제공 받은 목적이 달성된 경우에 수집된 개인의 정보는 재생할 수 없는 방법에
      의하여 서버 및 하드디스크 등에서 완전히 삭제되며 어 떠한 용도로도 열람
      또는 이용할 수 없도록 처리됩니다. 다만, 전자상거래등에서의 소비자보호에
      관한 법률 등 관계법령이 정하는 경우에는 하기 4개 항목에 한해 “회원”의 “서
      비스”및 “회원” 탈퇴 이후에도 법령이 정하는 일정한 기간에 한하여,
      “회원”님의 정보를 보유하게 됩니다.
      <br />
      가. 표시.광고에 관한 기록 : 6개월
      <br />
      나. 계약 또는 청약철회 등에 관한 기록 : 5년
      <br />
      다. 대금결제 및 재화 등의 공급에 관한 기록 : 5년
      <br />
      라. 소비자의 불만 또는 분쟁처리에 관한 기록 : 3년
      <br />
      <br />
      (2) 개인정보의 파기절차 및 파기방법
      <br />
      가. “회원”은 서비스 사이트(m-safety.riskzero.ai 및 그 외)에 로그인 후
      ‘마이메뉴{">"}내 정보’ 에서 개인정보를 수정하시거나 가입해지(회원탈퇴)를
      하실 수 있으며, 고객센터로 연락 시 본인확인절차를 거친 후 탈퇴하실 수
      있습니다.
      <br />
      나. 전자적 파일 형태로 저장된 개인정보는 복구할 수 없는 기술적 방법을
      사용하여 삭제합니다.
      <br />
    </Body>
    <Head>• 개인정보의 취급/위탁</Head>
    <Body>
      “회사”는 수집한 개인정보를 취급/위탁하지 않으며, 이러한 취급/위탁이 발생할
      경우 “회원”에게 사전 고지 및 동의절차를 거칩니다. “회원”은 개인정보의
      취급/위탁에 대하여 동의하거나 거부할 수 있습니다. 다만 제2 조 제3항에 따른
      개인정보의 취급/위탁이 발생할 경우 “회원”에의 고지 및 동의절차 없이 진행될
      수 있습니다.
    </Body>
    <Head>• 개인정보 처리의 거부 및 정정</Head>
    <Body>
      (1) “회원”은 “회사”의 개인정보의 처리에 동의하지 않는 경우 동의를
      거부하거나 가입해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우
      서비스의 일부 또는 전부 이용이 어려울 수 있습니다. 이러한 정보의 조회 및
      수정, 가입해지(회원탈퇴)는 어플리케이션(m-safety.riskzero.ai)및 서비스
      관련 사이트)에 로그인 후 ‘마이메뉴’의 ‘내 정보’에서 가능합니다. 혹은
      개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 본인확인 절차를
      거친 후 지체 없이 조치하겠습니다.
      <br />
      (2) ”회원”의 개인정보의 오류에 대해 정정할 경우, “회원”은 서비스 사이트
      등을 통해 스스로 정보를 정정할 수 있습니다. 또한 잘못된 개인정보를 제3
      자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여
      정정이 이루어지도록 할 것입니다. “회사”는 “회원” 의 요청에 의해 해지 또는
      삭제된 개인정보는 제 3조에 명시된 바에 따라 처리하고 그 외의 용도로 열람
      또는 이용할 수 없도록 처리하고 있습니다.
    </Body>
    <Head>• 개인정보 관리책임자 및 상담, 신고</Head>
    <Body>
      고객의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 당사는
      개인정보 관리책임자를 두고 있습니다. 고객의 개인정보와 관련한 문의사항이
      있으시면 아래 의 개인정보 관리책임자 또는 개인정보 관리담당자에게 연락
      주시기 바랍니다.
      <br />
      가. 최고 개인정보관리책임자(CPO) :
      <br />
      나. 개인정보관리 담당자 :<br />
      다. 소속부서 : ㈜리스크제로 사업본부
      <br />
      라. 전화번호 : (국번없이)070-4145-4354
      <br />
      마. 이메일 : hg_info@riskzero.kr
    </Body>
    <Head>• “회원”의 권리와 의무</Head>
    <Body>
      (1) “회원”은 개인정보를 최신의 상태로 정확하게 입력하여 불의의 사고를
      예방하여야 합니다.
      <br />
      이용자가 입력한 부정확한 정보로 인해 발생하는 사고의 책임은 이용자
      자신에게 있으며 타인 정보의 도용 등 허위정보를 입력할 경우 “회원”자격이
      상실될 수 있습니다.
      <br /> (2) “회원”은 개인정보를 보호받을 권리와 함께 “회원” 스스로를
      보호하고 타인의 정보를 침해하지 않을 의무도 가지고 있습니다. 개인정보가
      유출되지 않도록 조심하고 게시물을 포함한 타인의 개인정보를 훼손하지 않도록
      유의하여야 합니다. 만약 “회원”이 이 같은 책임을 다하지 못하고 타인의 정보
      및 존엄성을 훼손할 시에는 『정보통신망이용촉진및정보보호등에관한법률』
      등에 의해 처벌받을 수 있습니다.
      <br />
      (3) “회원”이 타인의 성명, 휴대폰번호 등 기타 개인정보를 도용하여 회원가입
      등을 하였음을 알게 된 때에는 지체 없이 해당 아이디에 대한 필요한 조치를
      취합니다.
    </Body>
  </View>
);
