import React, { useEffect, useState } from "react";
import styled from "styled-components";
import GlobalStyles from "./styles/GlobalStyles";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Policy from "./routes/Policy";
import Service from "./routes/Service";

const Wrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: 0px 16px;
`;

function App() {
  return (
    <BrowserRouter>
      <Wrapper>
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/service" element={<Service />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </Wrapper>
    </BrowserRouter>
  );
}

export default App;
