export const colors = {
  white: "#fff",
  black: "#000",

  gray: "#858585",
  footerTextGray: "#A1A1A1",
  lightGray: "#EEEEEE",
  ultraLightGray: "#F8F8F8",
  buttonShadow: "#00000029",
  activeColor: "#0DE170",
  footerBackgroundColor: "#222C3C",
};
