import React from "react";
import styled from "styled-components";
import { IoArrowBackSharp } from "react-icons/io5";

const View = styled.div`
  display: flex;
  height: 66px;
  justify-content: center;
  align-items: center;
  position: relative;
`;

const IconBox = styled.div`
  position: absolute;
  left: 0px;
  cursor: pointer;
  &:active {
    opacity: 0.5;
  }
`;
const Text = styled.span`
  font-weight: bold;
`;

function Header({ title, back = false, onClickBack }) {
  return (
    <View>
      {back && (
        <IconBox>
          <IoArrowBackSharp />
        </IconBox>
      )}
      <Text>{title}</Text>
    </View>
  );
}

export default React.memo(Header);
