import React from "react";
import styled from "styled-components";

const View = styled.div`
  flex: 1;
`;
const Head = styled.span`
  display: flex;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 14px;
  margin-top: 30px;
`;
const Body = styled.span`
  line-height: 28px;
  font-size: 16px;
  text-align: justify;
  letter-spacing: 0.8px;
`;

// eslint-disable-next-line import/no-anonymous-default-export
export default () => (
  <View>
    <Head>제1장 총 칙</Head>
    <Head>제1조 [목적]</Head>
    <Body>
      이 약관은 한미글로벌과 리스크제로(이하 “회사”라 한다)가 제공하는 건설현장
      안전시정 조치 어플리케이션 RiskZero_HG 서비스(이하 “서비스” 라고 한다)를
      이용함에 있어 서비스 이용조건 및 절차와 이용자의 권리, 의무 및 책임사항을
      규정하는 것을 목적으로 합니다.
    </Body>
    <Head>제2조 [용어의 정의]</Head>
    <Body>
      • 이 약관에서 사용하는 용어의 정의는 아래와 같습니다.
      <br />
      • RiskZero_HG : 기존 하드카피 형태의 안전시정조치요구서 발행 과정을 온라인
      플랫폼 상으로 옮겨, 현장의 안전 위반사항과 처리 현황을 공유하고 이와
      연관된 각종 정보 제공을 목적으로 회사가 제공하는 어플리케이션을 말합니다.
      <br />
      • 이용계약 : 서비스를 제공 받기 위하여 회사와 이용자 간에 체결하는 계약을
      말합니다.
      <br />
      • 이용신청 : 회사가 정한 별도의 기준과 절차에 따라 서비스 이용을 신청하는
      것을 말합니다.
      <br />
      • 이용정지 : 회사가 정한 일정한 요건에 따라 서비스 이용을 보류하는 것을
      말합니다.
      <br />
      • 해지 : 이용자가 서비스 개통 후에 이용계약을 해약하는 것을 말합니다.
      <br />
      • 이용자 : 회사와 서비스 이용계약을 체결한 사람을 말합니다.
      <br />
      • 이용자 아이디(ID) : 이용자 식별과 서비스 이용을 위하여 이용자가 선정하고
      회사가 인정한 숫자, 또는 이메일을 말합니다.
      <br />
      • 콘텐츠: 어플리케이션상에 게재된 각종 사진, 일러스트, 템플릿, 아이콘,
      편집디자인 소스 등을 말합니다.
      <br />
      • 제 1항에서 정의되지 않은 용어의 의미는 관련법령 및 일반적인 거래관행을
      따릅니다.
      <br />
    </Body>
    <Head>제3조 [약관의 효력 및 변경]</Head>
    <Body>
      회사는 필요하다고 인정되는 경우 이 약관을 변경할 수 있으며 이용자에게
      공지함으로써 효력이 발생합니다.
      <br />
      • 이 약관은 회사가 서비스를 통하여 이용자가 확인할 수 있도록 공지하거나
      전자우편 등의 방법으로 이용자에게 통지함으로써 효력이 발생합니다.
      <br />• 이 약관의 적용 기간은 이용자의 가입일부터 해지일까지로 규정합니다.{" "}
      <br />
      • 회사는 합리적인 사유가 발생할 경우에, 전자 상거래 등에서의 소비자보호에
      관한 법률, 약관의 규제에 관한 법률, 전자문서 및 전자거래 기본법,
      소비자기본법 등 관련법령을 위배하지 않는 범위 내에서 이 약관을 변경할 수
      있습니다. <br />
      • 변경된 약관은 이용자에게 전자우편으로 발송하여 개정 내용을 공지합니다.
      단, 요금 등 이용고객의 권리 또는 의무에 중대한 영향을 주는 규정의 변경은
      최소한 적용일자 30일 전에 공지합니다.
      <br />
      • 이용고객은 변경된 약관 사항에 동의하지 않으면 서비스 이용을 중단하고
      이용계약을 해지할 수 있으며, 유료서비스 이용자가 약관의 개정에 동의하지
      아니하여 서비스를 해지하는 경우 회사가 정한 환불정책에 따릅니다. 약관의
      효력 발생일 이후 별도의 거부의사를 표시하지 않고 계속된 서비스 이용은
      약관의 변경사항에 동의한 것으로 간주합니다.
      <br />
      • 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래
      등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률,
      공정거래위원회가 정하는 전자상거래 등에서의 소비자 보호지침 및 관련법령
      또는 상관례에 따릅니다.
      <br />
    </Body>
    <Head>제2장 서비스 이용계약</Head>
    <Head>제4조 [서비스 이용 계약의 성립]</Head>
    <Body>
      • 이용계약은 이용자가 이 약관에 동의하면서 이용신청을 하고, 이에 대하여
      회사의 이용승낙으로 성립합니다.
      <br />
      • 이용계약의 성립시기는 회사의 승낙이 이용자에게 도달한 시점으로 하며,
      이는 특별한 사정이 없는 한 서비스 상의 ‘신청 절차’에 가입완료 문구가
      표시된 시점을 의미합니다.
      <br />
    </Body>
    <Head>제5조 [서비스 이용신청]</Head>
    <Body>
      • 서비스의 이용을 원하고 이 약관의 내용을 동의하는 이용자에 한하여 회사가
      정한 가입 양식에 따라 회원정보를 기재하고, 서비스상에서 아이디 및
      비밀번호를 설정하여 회원가입을 신청함으로써, 이용신청을 합니다.
      <br />
      • 회사는 업무 수행상 또는 기술상 지장이 없는 경우 접수 순서에 따라 서비스
      이용을 승낙합니다.
      <br />
    </Body>
    <Head>제6조 [서비스 이용 및 제한]</Head>
    <Body>
      • 회사는 제5조의 규정에 의하여 이용신청을 접수한 후 즉시 이를 승낙하고
      정해진 절차에 따라 서비스 이용을 개통합니다.
      <br />
      • 회사는 다음 각호에 해당하는 신청에 대해서는 신청의 승낙을 거부할 수
      있습니다.
      <br />
      • 이용자가 신청한 아이디가 이미 다른 이용자에 의해 쓰이고 있는 경우
      <br />
      • 이용신청 시 타인 명의의 사용 및 허위내용의 기재 등 정확하지 않은 정보를
      사용하여 신청한 경우
      <br />
      • 이용자가 서비스의 정상적인 제공을 저해하거나 다른 이용자의 서비스 이용에
      지장을 줄 것으로 예상되는 경우
      <br />
      • 신용정보의 이용과 보호에 관한 법률에 따라 신용불량자로 등록된 경우
      <br />
      • 방송통신위원회의 인터넷 서비스 불량 이용자로 등록된 경우
      <br />
      • 이용신청 요건이 미비 되었거나 본인 확인할 수 없을 경우
      <br />
      • 기타 이용 신청 고객의 귀책사유로 이용승낙이 곤란한 경우
      <br />
      • 회사는 다음 각호에 해당하는 신청에 대해서는 해당 사유가 해소될 때까지 그
      승낙을 유보할 수 있습니다.
      <br />
      • 회사의 서비스를 위한 제반 용량이 부족한 경우
      <br />
      • 회사의 기술상 또는 업무상 지장이 있는 등 회사의 사정으로 승낙이 곤란한
      경우
      <br />
    </Body>
    <Head>제7조 [개인정보의 보호]</Head>
    <Body>
      • 회사는 관련법령이 정하는 바에 따라 이용자의 계정정보(연락처, 이메일
      등)를 포함한 이용자의 개인정보를 보호하기 위하여 노력합니다. 이용자
      개인정보의 보호 및 사용에 대해서는 관련법령 및 서비스상에 게시된 회사의
      개인정보처리방침에 따릅니다.
      <br />
      • 회사의 공식 서비스 이외의 웹에서 링크된 플랫폼에서는 회사의
      개인정보처리방침이 적용되지 않습니다.
      <br />
      • 회사는 이용자의 귀책사유로 인해 노출된 이용자의 계정정보를 포함한 모든
      개인정보에 대해서 일체의 책임을 지지 않습니다.
      <br />
    </Body>
    <Head>제3장 서비스 제공 및 이용</Head>
    <Head>제8조 [서비스의 내용]</Head>
    <Body>
      • 회사가 제공하는 서비스의 내용은 다음과 같습니다. <br />
      • 안전시정조치 업무 지원 서비스 : 건설사업관리자가 현장의 안전 위반사항에
      대해 시정조치를 지시하고, 시공자가 이에 대한 조치 결과를 보고하는 일련의
      업무 절차를 수행할 수 있게 지원하는 서비스 <br />
      • 기타 현장 안전관리를 위한 제반 서비스
      <br />
      • 회사는 필요한 경우 서비스의 내용을 추가 또는 변경할 수 있습니다. 이 경우
      회사는 추가 또는 변경 내용을 이용자에게 공지해야 합니다. <br />
    </Body>
    <Head>제10조 [서비스의 제공 및 변경]</Head>
    <Body>
      • 서비스 이용시간은 회사의 업무상 또는 기술상 특별한 지장이 없는 한
      연중무휴 1일 24시간을 원칙으로 합니다.
      <br />
      • 다음 각호에 해당하는 경우에 회사는 서비스 페이지에 그 사유 및 기간을
      공지하고 서비스를 중지할 수 있습니다.
      <br />
      • 새로운 서비스 제공, 서비스의 개선을 위한 정기점검, 각종 버그 패치 등
      운영상 또는 기술상 필요한 경우
      <br />
      • 통신회선 장애 등 회사가 서비스의 제공을 정지할 필요가 있다고 판단되는
      경우
      <br />
      • 천재지변, 국가비상사태, 정전, 서비스 설비의 장애 등으로 정상적인 서비스
      이용에 지장이 있을 경우
      <br />
      • 기타 회사와 이용자가 협의한 경우
      <br />
      • 회사는 이용자의 공사 내 참여 역할 또는 회사의 운영정책에 따라 기능
      권한을 관리자(admin), PM 현장, 시공사, 발주처 등의 등급으로 부여할 수
      있습니다.
      <br />
      • 회사는 다음 각호에 해당하는 경우에 한하여 이용자의 서비스 이용을 정지할
      수 있으며 그 사실을 이용자 또는 그 대리인에게 통지합니다. 다만 회사가
      긴급하게 이용을 정지할 필요가 있다고 인정하는 경우나, 이용자의 귀책사유로
      인하여 통지할 수 없는 경우에는 통지를 생략할 수 있습니다.
      <br />
      • 유료서비스를 사용한 이용자가 요금 등을 납입할 의무를 이행하지 아니한
      경우
      <br />
      • 제11조의 규정에 의한 이용자의 의무를 이행하지 아니한 경우
      <br />
      • 다른 이용자 또는 제3자의 지적재산권을 침해하거나 명예를 손상한 경우
      <br />
      • 이용자가 서비스를 별도의 이용 계약 없이 재판매하거나 변형하여 제3자가
      이용하도록 하는 경우
      <br />
      • 제4항의 규정에 의하여 이용정지의 통지를 받은 이용자 또는 그 대리인은 그
      이용정지의 통지에 대하여 이의신청을 할 수 있습니다.
      <br />
      • 회사는 제5항의 규정에 의하여 이의신청이 접수된 경우, 즉시 이를 확인하고
      그 결과를 이용자 또는 그 대리인에게 통지해야 합니다.
      <br />
      • 회사는 이용정지 기간에 그 이용정지 사유가 해소된 것이 확인된 경우에는
      이용정지조치를 즉시 해제해야 합니다.
      <br />
      • 회사는 이용자의 정보가 부당한 목적으로 사용되는 것을 방지하고 보다
      원활한 서비스 제공을 위하여 24개월 이상 계속해서 로그인을 포함한 서비스
      이용이 없는 이용 아이디를 휴면 아이디로 분류하고 서비스 이용을 제한할 수
      있습니다.
      <br />
    </Body>
    <Head>제11조 [이용자에 대한 통지]</Head>
    <Body>
      • 회사가 이용자에 대한 통지를 하는 경우 이 약관에 별도 규정이 없는 한
      회원이 지정한 이메일, 플랫폼상의 알림 메시지 또는 전자 게시물 등을 통하여
      통지할 수 있습니다.
      <br />• 회사는 회원 전체 혹은 이용자에 대한 통지의 경우 7일 이상 플랫폼
      또는 공지사항 게시판에 게시함으로써 제1항의 통지에 갈음할 수 있습니다.
    </Body>
    <Head>제12조 [저작권]</Head>
    <Body>
      • 서비스를 통해 제공되는 콘텐츠의 저작권은 그 적법한 저작자 및/또는 관련
      권리자(이하 ‘권리자’라 함)에게 귀속됩니다. 이용자는 권리자의 허락, 회원
      여부, 유료 서비스의 종류 등에 따라 허용되는 목적과 기간의 범위 내에서만
      사용 허락을 받는 것이며, 회사가 콘텐츠의 저작권 자체를 판매하는 것은
      아닙니다.
      <br />
      • 이용자는 회사와 사전 협의없이 서비스를 통해 제공되는 콘텐츠를 무단으로
      복제, 배포, 출판, 방송, 공연, 전시 및 전송해서는 안됩니다.
      <br />
      • 제1항에도 불구하고 회사는 이용자가 서비스 내에 게시하는 콘텐츠를
      검색결과, 서비스 소개, 관련 프로모션 등에 노출할 수 있으며, 해당 노출을
      위해 필요한 범위 내에서는 일부 수정, 복제 및 편집하여 게시할 수 있습니다.
      이 경우, 회사는 저작권법 규정을 준수하며, 이용자는 언제든지 고객센터 또는
      서비스 내 관리기능을 통해 해당 게시물에 대해 삭제, 검색결과 제외, 비공개
      등의 조치를 취할 수 있습니다.
      <br />
      • 회사는 제3항 이외의 방법으로 이용자의 게시물을 이용하고자 하는 경우에는
      전화, 문자, 팩스, 전자우편 등을 통해 사전에 회원의 동의를 얻어야 합니다
      <br />
    </Body>
    <Head>제13조 [게시물의 관리]</Head>
    <Body>
      • 회원의 게시물이 정보통신망 이용촉진 및 정보보호에 관한 법률, 저작권법,
      개인정보호법, 위치정보의 보호 및 이용 등에 관한 법률 등 관련 법령에
      위반되는 내용을 포함하는 경우, 해당 게시물의 권리자는 관련 법령이 정한
      절차에 따라 해당 게시물의 게시중단 및 삭제 등을 요청할 수 있으며, 회사와
      회원은 관련 법령에 따른 조치를 취하여야 합니다.
      <br />
      • 회사는 제1항에 따른 권리자의 요청이 없는 경우라도 특정 게시물에 제3자에
      대한 권리침해가 인정될 만한 사유가 있거나 기타 회사 정책 및 관련 법령에
      위반되는 경우에는 관련 법령에 따라 해당 게시물에 대해 임시조치 등을 취할
      수 있습니다.
      <br />
      • 회사는 회사와의 사전 협의 없이 광고∙홍보용으로 게시된 게시물이나,
      사회미풍양속을 저해하는 용도의 게시물에 대하여 해당 게시물의 게시중단 및
      삭제 등의 조치를 취할 수 있습니다.
      <br />
      • 회사는 서비스별로 이용자의 필요 때문에 저장하고 있는 자료에 대하여
      일정한 저장 기간을 정할 수 있으며, 필요에 따라 그 기간을 변경할 수
      있습니다.
      <br />
      • 회사는 서비스 이용중지 및 해지 기간이 90일 이상 경과된 이용자의 경우
      해당 계정에 남아있는 모든 데이터에 대해 복구를 전제하지 않고 삭제할 수
      있습니다.
      <br />
    </Body>
    <Head>제4장 계약당사자의 의무</Head>
    <Head>제14조 [회사의 의무]</Head>
    <Body>
      • 회사는 이용자에게 제공하는 서비스를 지속적이고 안정적으로 제공하기
      위하여 노력할 의무가 있습니다.
      <br />
      • 회사는 안정적인 서비스를 지속해서 제공하기 위하여 설비에 장애가 생기거나
      멸실되었을 때 지체 없이 이를 수리 또는 복구해야 합니다. 다만, 천재지변이나
      비상사태 등 부득이한 경우에는 서비스를 일시 중단하거나 영구 중지할 수
      있습니다.
      <br />
      • 회사는 서비스 제공과 관련하여 취득한 이용자의 개인정보를 본인의 동의
      없이 타인에게 누설하거나 배포할 수 없으며 서비스 관련 업무 이외의
      목적으로는 사용할 수 없습니다.
      <br />
      • 다만, 다음 각호에 해당하는 경우에는 예외로 합니다.
      <br />
      • 금융실명거래 및 비밀보장에 관한 법률, 신용정보의 이용 및 보호에 관한
      법률, 전기통신 기본법, 전기통신사업법, 소비자보호법, 형사소송법 등 관련
      법령에 특별한 규정이 있는 경우
      <br />
      • 방송통신위원회가 이 약관에 의하여 이용 제한을 받은 이용자의 이용 아이디,
      성명, 생년월일 및 이용제한 사유 등을 요청하는 경우
      <br />
      • 유료서비스 제공에 따른 요금 정산을 위하여 필요한 경우
      <br />
      • 기타 관계법령에 의해 국가기관의 요구나 관련 위원회의 요청 등 법률의
      규정에 따른 적법한 절차에 의한 경우
      <br />
      • 회사가 이용자의 개인정보를 이용하거나 제3자에게 제공하는 경우에는 반드시
      해당 이용자에게 개별적으로 전자우편이나 전화 등으로 통지하여야 합니다.
      <br />
      • 회사는 개인정보의 수집 목적을 달성한 후에는 해당 이용자의 개인정보를
      지체 없이 파기하여야 합니다. 다만, 다음 각호에 해당하는 경우에는 예외로
      개인정보를 보유할 수 있습니다. <br />
      • 상법 등 관계 법령의 규정에 의하여 보존할 필요성이 있는 경우
      <br />
      • 이 약관 내 별도 규정에 의하여 보유 기간을 이용자에게 알리거나 명시한
      경우
      <br />
      • 이용자에게 개별적으로 동의를 받은 경우
      <br />
      • 회사는 다음 각 호의 내용을 포함하는 개인정보보호 방침을 정하여 지속해서
      실행하여야 하며, 서비스 페이지를 통하여 이용자에게 공지하여야 합니다.
      <br />
      • 개인정보 수집항목
      <br />
      • 개인정보의 수집 및 이용목적
      <br />
      • 개인정보의 보유 및 이용 기간
      <br />
      • 개인정보보호 방침의 개정에 관한 사항
      <br />
      • 회사는 이용계약의 체결, 계약사항의 변경 및 해지 등 이용자와의 계약 관련
      절차 및 내용 등에 있어 이용자에게 편의를 제공하도록 노력해야 합니다.
      <br />
      • 회사는 개인정보보호 정책에 따라 이용자의 개인정보를 취급하면서 보안을
      지키기 위한 기술적, 관리적 대책을 마련하여야 합니다.
      <br />
    </Body>
    <Head>제15조 [이용자의 의무]</Head>
    <Body>
      • 이용자는 관계 법령, 이 약관의 규정, 이용안내 및 주의사항 등 회사가
      통지하는 사항을 준수하여야 하며, 기타 회사의 업무에 지장을 초래하는 행위를
      해서는 안됩니다.
      <br />
      • 이용자는 서비스 계약에 필요한 정보 변경 시 지체 없이 개인정보수정을 통해
      갱신하여야 합니다.
      <br />
      • 이용자의 계정에 관한 관리 책임은 이용자에게 있으며, 이를 제3자가
      이용하도록 하여서는 안됩니다. <br />
      • 이용자는 서비스를 이용하면서 공공질서 또는 미풍양속을 해치는 행위, 또는
      다음 각호에 해당하는 행위를 해서는 안됩니다.
      <br />
      • 다른 이용자의 이용 아이디를 부정 사용하는 행위
      <br />
      • 해킹 행위 또는 컴퓨터 바이러스의 유포행위
      <br />
      • 타인의 의사에 반하여 광고성 정보 등 유사한 내용을 지속해서 전송하는 행위
      <br />
      • 타인의 지적 재산권 등을 침해하는 행위
      <br />
      • 범죄행위를 목적으로 하거나 범죄행위를 교사하는 행위
      <br />
      • 반국가적 범죄의 수행을 목적으로 하는 행위
      <br />
      • 선량한 풍속 또는 기타 사회질서를 해치는 행위
      <br />
      • 서비스의 안전적인 운영이 지장을 주거나 줄 우려가 있는 일체의 행위
      <br />
      • 이용자는 회사 외의 정당한 계약 또는 허락 없이 서비스를 재판매 할 수
      없고, 이를 이용하여 영업활동을 할 수 없으며, 이러한 영업활동의 결과(기타
      회원이 약관에 위반한 영업활동을 한 결과 포함)에 대하여 회사는 어떠한
      책임도 부담하지 않습니다. 회원은 이러한 영업활동에 대하여 회사에게 손해를
      배상할 의무가 있습니다.
      <br />
      • 이용자는 회사 홈페이지 상의 공지사항 및 이용약관의 수정사항 등을
      확인하고 이를 준수할 의무가 있으며 기타 회사의 업무에 방해되는 행위를
      하여서는 안 됩니다.
      <br />
    </Body>
    <Head>제16조 [이용자 정보 열람 및 정정 요구]</Head>
    <Body>
      • 회사는 이용자가 방문하거나 이용 아이디 등을 이용하여 자신의 개인정보에
      대한 열람 또는 정정을 요구하는 경우에는 본인 여부를 확인하고 지체 없이
      필요한 조치를 취하여야 합니다.
      <br />
      • 회사는 이용고객이 서비스 화면에서 자신의 개인정보에 대한 열람 또는
      정정을 수행할 수 있도록 필요한 조치를 취하여야 합니다.
      <br />
      • 회사는 이용고객의 대리인이 방문하여 열람 또는 정정을 요구하는 경우에는
      대리관계를 나타내는 증표를 제시하도록 요구할 수 있습니다.
      <br />
      • 회사는 이용자 서비스 개발 및 연구를 위해, 이용자의 업무 정보를 사용할 수
      있습니다.
      <br />
    </Body>
    <Head>제5장 회원탈퇴 및 이용제한</Head>
    <Head>제17조 [회원탈퇴 및 계약해지]</Head>
    <Body>
      • 이용자는 언제든지 회원탈퇴 신청을 할 수 있으며, 회사는 회원탈퇴에 관한
      규정에 따라 이를 처리합니다. <br />• 유료 서비스를 이용 중인 이용자가
      서비스를 탈퇴하는 경우 해당 유료 서비스의 이용계약은 즉시 해지되며, 이에
      대한 이용자가 서비스 이용계약을 해지할 경우, 관련법령 및 회사의
      개인정보보호정책에서 정한 바에 따라 회사가 회원정보를 보유하는 경우를
      제외하고는, 해지 즉시 이용자의 모든 데이터는 소멸됩니다. 다만, 이용자가
      서비스 이용 중 다른 회원에게 제공(또는 공유)한 콘텐츠의 경우 다른 회원이
      보유하는 콘텐츠는 소멸되지 않습니다.
    </Body>
    <Head>제18조 [이용제한 등]</Head>
    <Body>
      • 회사는 이용자가 이 약관의 의무 또는 관련법령을 위반하거나 서비스의
      정상적인 운영을 방해한 경우, 서비스 이용을 ‘경고’, ‘일시정지’ 및
      ‘계약해지’로 단계적으로 제한할 수 있습니다.
      <br />
      • 회사는 제1항의 규정에도 불구하고, 주민등록법을 위반한 명의도용 및
      결제도용, 저작권법을 위반한 불법프로그램의 제공 및 운영방해, 정보통신망
      이용촉진 및 정보보호 등에 관한 법률을 위반한 불법통신 및 해킹,
      악성프로그램의 배포, 접속권한 초과행위 등과 같이 관련법령을 위반한
      경우에는 즉시 계약을 해지할 수 있습니다. 이에 따른 계약해지시 이용자가
      서비스 이용을 통해 획득한 혜택 등은 모두 소멸되며, 회사는 이에 대해 별도로
      보상하지 않습니다.
      <br />
      • 회사가 제1항에 따라 이용자의 서비스 이용을 제한하거나 계약을 해지하는
      경우, 제한의 조건 및 세부내용은 플랫폼상에 게시된 이용제한정책 등에서 정한
      바에 따르며, 회사는 이용자에게 제11조에서 정한 방법으로 통지합니다.
      <br />
      • 이용자는 회사의 이용제한 조치 등에 대하여 회사가 정한 절차에 따라
      이의신청을 할 수 있습니다. 이용자의 이의 사유가 정당하다고 인정되는 경우
      회사는 즉시 이용자의 서비스 이용을 재개하여야 합니다.
      <br />
    </Body>
    <Head>제19조 [손해배상]</Head>
    <Body>
      • 회사는 유료 서비스 이용이 불가하거나 이용에 장애가 있는 경우, 회사의
      유료 서비스를 이용중인 이용자에게 그 손해를 배상합니다. 다만, 회사의 고의
      또는 과실 없이 이용자에게 발생한 손해에 대하여는 책임을 지지 아니합니다.
      회사는 해당 이용자에게 손해를 배상하는 경우, 그 방식과 절차 등에 관해
      콘텐츠이용자보호지침의 관련 규정 및 기타 상관례를 따릅니다.
      <br />
      • 제1항에도 불구하고, 이용자가 이용 불가 또는 이용 장애의 사실을 회사에
      접수한 이후 72시간 이내에 정상화된 경우, 회사는 이에 대한 책임을 지지
      않습니다. <br />
      • 이용자가 이 약관의 의무를 위반함으로 인하여 회사에 손해를 입힌 경우 또는
      이용자가 서비스의 이용과 관련하여 회사에 손해를 입힌 경우 이용자는 회사에
      대하여 당해 손해를 배상하여야 합니다.
      <br />
      • 이용자가 서비스를 이용함에 있어 행한 불법행위 또는 본 약관을 위반한
      행위로 회사가 당해 이용자 외의 제3자로부터 손해배상청구 또는 소송 등 각종
      이의제기를 받는 경우 당해 이용자는 자신의 책임과 비용으로 회사를
      면책시켜야 하며, 회사가 면책되지 못한 경우 당해 이용자는 그로 인하여
      회사에 발생한 모든 손해를 배상할 책임이 있습니다.
      <br />
    </Body>
    <Head>제20조 [책임의 한계]</Head>
    <Body>
      • 회사는 전시, 사변, 천재지변, 국가비상사태 또는 이에 준하는 불가항력으로
      인하여 서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이
      면제됩니다.
      <br />
      • 회사는 이용자의 귀책사유로 인한 이용 불가 또는 이용 장애에 대하여는
      책임을 지지 아니하며, 기간통신사업자가 전기통신서비스를 중지하거나
      정상적으로 제공하지 아니하여 이용자에게 손해가 발생한 경우에는 책임이
      면제됩니다.
      <br />
      • 회사는 서비스용 설비의 보수, 교체, 정기점검, 공사 등 부득이한 사유로
      발생한 손해에 대한 책임이 면제됩니다.
      <br />
      • 회사는 이용자가 서비스와 관련하여 게재한 정보, 자료, 사실의 신뢰도,
      정확성 등의 내용에 관하여는 책임을 지지 않습니다.
      <br />
      • 회사는 이용자 간 또는 이용자와 제3자 상호간에 서비스를 매개로 하여 거래
      등을 한 경우에는 책임이 면제됩니다.
      <br />
      • 회사는 무료로 제공되는 서비스 이용과 관련하여 관련법령에 특별한 규정이
      없는 한 책임을 지지 않습니다.
      <br />
    </Body>
    <Head>제21조 [준거법 및 재판관할]</Head>
    <Body>
      • 이 약관의 해석 및 회원 간의 분쟁에 대하여는 대한민국의 법령을 준거법으로
      합니다.
      <br />• 회사와 회원 간 발생한 분쟁에 관한 소송은 서울중앙지방법원을
      전속관할로 합니다.
    </Body>
  </View>
);
