import React from "react";
import styled from "styled-components";
import { IoArrowForwardSharp } from "react-icons/io5";

const View = styled.div`
  flex: 1;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 12px 0px;
  cursor: pointer;
  &:active {
    opacity: 0.5;
  }
`;
const Text = styled.span``;
const IconBox = styled.div`
  position: absolute;
  right: 0px;
  cursor: pointer;
  &:active {
    opacity: 0.5;
  }
`;

function NavIcon({ title, onPress = () => null }) {
  return (
    <View onClick={onPress}>
      <IconBox>
        <IoArrowForwardSharp />
      </IconBox>
      <Text>{title}</Text>
    </View>
  );
}

export default React.memo(NavIcon);
