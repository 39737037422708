import React from "react";
import styled from "styled-components";
import Header from "../components/Header";
import ServiceContents from "../components/Tests/ServiceContents";

const View = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function Service() {
  return (
    <View>
      <Header title="RiskZero_HG 서비스이용약관" />
      <ServiceContents />
    </View>
  );
}

export default React.memo(Service);
