import React from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import Header from "../components/Header";
import NavIcon from "../components/NavIcon";

const View = styled.div`
  flex: 1;
  justify-content: center;
  align-items: center;
`;

function Home() {
  const navigate = useNavigate();
  const goToService = () => {
    navigate("/service");
  };
  const goToPolicy = () => {
    navigate("/policy");
  };
  return (
    <View>
      <Header title="RiskZero_HG 약관" />
      <NavIcon title="서비스 이용약관" onPress={goToService} />
      <NavIcon title="개인정보 처리방침" onPress={goToPolicy} />
    </View>
  );
}

export default React.memo(Home);
